window.addEventListener('load', init);


function init(e) {
  console.log('load');
  const SliderCarrosselBanner = document.querySelector('[data-carousel="carrossel-banner"]');
  const SliderCarrosselProdutos = document.querySelector('[data-carousel="carrossel-produtos"]');
  const SliderCarrosselReceitas = document.querySelector('[data-carousel="carrossel-receitas"]');
  const SliderCarrosselCadeia = document.querySelector('[data-carousel="carrossel-cadeia"]');
  const SliderCarrosselProdutoInterna = document.querySelector('[data-carousel="carrossel-prod-interna"]');
  const SliderCarrosselPerguntasReceitas = document.querySelector('[data-carousel="carrossel-perguntas-receitas"]');
  const SliderCarrosselBuscaReceitas = document.querySelector('[data-carousel="carrossel-busca-receitas"]');
  const SliderCarrosselBuscaProdutos = document.querySelector('[data-carousel="carrossel-busca-produtos"]');
  const SliderCarrosselBuscaNovidades = document.querySelector('[data-carousel="carrossel-busca-novidades"]');
  const SliderCarrosselAvaliacoes = document.querySelector('[data-carousel="carrossel-avaliacoes"]');

  if (SliderCarrosselBanner) {
    const Carrossel = new Swiper(SliderCarrosselBanner, {
      speed: 400,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: SliderCarrosselBanner.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: SliderCarrosselBanner.querySelector('.swiper-button-next'),
        prevEl: SliderCarrosselBanner.querySelector('.swiper-button-prev'),
      }
    });
  }

  if (SliderCarrosselProdutos) {
    const Carrossel = new Swiper(SliderCarrosselProdutos, {
      speed: 0,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: SliderCarrosselProdutos.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: SliderCarrosselProdutos.querySelector('.swiper-button-next'),
        prevEl: SliderCarrosselProdutos.querySelector('.swiper-button-prev'),
      }
    });
  }

  if (SliderCarrosselReceitas) {
    const Carrossel = new Swiper(SliderCarrosselReceitas, {
      speed: 0,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: SliderCarrosselReceitas.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: SliderCarrosselReceitas.querySelector('.swiper-button-next'),
        prevEl: SliderCarrosselReceitas.querySelector('.swiper-button-prev'),
      }
    });
  }

  if (SliderCarrosselCadeia) {
    const Carrossel = new Swiper(SliderCarrosselCadeia, {
      speed: 400,
      loop: true,
      slidesPerView: 3,
      spaceBetween: 20,
      direction: 'vertical',
      centeredSlides: true,
      initialSlide: 1,
      pagination: {
        el: SliderCarrosselCadeia.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: SliderCarrosselCadeia.querySelector('.swiper-button-next'),
        prevEl: SliderCarrosselCadeia.querySelector('.swiper-button-prev'),
      },
    });
  }

  if (SliderCarrosselBuscaReceitas) {
    const Carrossel = new Swiper(SliderCarrosselBuscaReceitas, {
      speed: 400,
      loop: false,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 4,
          spaceBetween: 20
        }
      },
      pagination: {
        el: SliderCarrosselBuscaReceitas.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: SliderCarrosselBuscaReceitas.querySelector('.swiper-button-next'),
        prevEl: SliderCarrosselBuscaReceitas.querySelector('.swiper-button-prev'),
      }
    });
  }

  if (SliderCarrosselPerguntasReceitas) {
    var paginationNome = ['Tempo', 'Modo de preparo', 'Proteína', 'Resultado']
    const Carrossel = new Swiper(SliderCarrosselPerguntasReceitas, {
      speed: 400,
      loop: false,
      slidesPerView: 1,
      noSwiping: true,
      noSwipingClass: 'no-swiping',
      fadeEffect: {
        crossFade: true,
      },
      effect: 'fade',
      pagination: {
        el: SliderCarrosselPerguntasReceitas.querySelector('.swiper-pagination'),
        clickable: false,
        renderBullet: function (index, className) {
          realIndex = index + 1
          return '<span class="' + className + '">' + realIndex + '. ' + (paginationNome[index]) + '</span>'
        },
      },
    })

    const btnsNext = document.querySelectorAll('.opcoes .btn')
    for (var btnNext of btnsNext) {
      btnNext.addEventListener('click', function () {
        Carrossel.slideNext()
      })
    }

    const btnsPagination = document.querySelectorAll('.swiper-pagination-bullet')
    const estrelas = document.querySelector('.estrelas')
    Carrossel.on('slideChange', function () {
      estrelas.removeAttribute('class')
      estrelas.classList.add('estrelas')
      if (this.activeIndex === 0) {
        estrelas.classList.add('step-1')
      }
      if (this.activeIndex === 1) {
        estrelas.classList.add('step-2')
        btnsPagination[0].classList.add('swiper-pagination-bullet-active')
      }
      if (this.activeIndex === 2) {
        estrelas.classList.add('step-3')
        btnsPagination[0].classList.add('swiper-pagination-bullet-active')
        btnsPagination[1].classList.add('swiper-pagination-bullet-active')
      }
      if (this.activeIndex === 3) {
        estrelas.classList.add('step-4')
        btnsPagination[0].classList.add('swiper-pagination-bullet-active')
        btnsPagination[1].classList.add('swiper-pagination-bullet-active')
        btnsPagination[2].classList.add('swiper-pagination-bullet-active')
        btnsPagination[3].classList.add('animate')

        let step1 = this.slides[3].querySelector('.step-1')
        let step2 = this.slides[3].querySelector('.step-2')
        let step3 = this.slides[3].querySelector('.step-3')
        const btnNovaBusca = document.querySelector('.text-novabusca')
        setTimeout(function () {
          step1.classList.remove('active')
          step2.classList.add('active')
          estrelas.classList.remove('step-4')
          estrelas.classList.add('step-5')
        }, 2000)
        setTimeout(function () {
          step2.classList.remove('active')
          step3.classList.add('active')
          estrelas.classList.remove('step-5')
          estrelas.classList.add('step-6')
          btnNovaBusca.style.display = 'block'
        }, 4000)
      }
    })


  }

  if (SliderCarrosselBuscaProdutos) {
    const Carrossel = new Swiper(SliderCarrosselBuscaProdutos, {
      speed: 400,
      loop: false,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 4,
          spaceBetween: 20
        }
      },
      pagination: {
        el: SliderCarrosselBuscaProdutos.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: SliderCarrosselBuscaProdutos.querySelector('.swiper-button-next'),
        prevEl: SliderCarrosselBuscaProdutos.querySelector('.swiper-button-prev'),
      }
    });
  }

  if (SliderCarrosselBuscaNovidades) {
    const Carrossel = new Swiper(SliderCarrosselBuscaNovidades, {
      speed: 400,
      loop: false,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 4,
          spaceBetween: 20
        }
      },
      pagination: {
        el: SliderCarrosselBuscaNovidades.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: SliderCarrosselBuscaNovidades.querySelector('.swiper-button-next'),
        prevEl: SliderCarrosselBuscaNovidades.querySelector('.swiper-button-prev'),
      }
    });
  }

  if (SliderCarrosselProdutoInterna) {
    const Carrossel = new Swiper(SliderCarrosselProdutoInterna, {
      speed: 0,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: SliderCarrosselProdutoInterna.querySelector('.swiper-pagination'),
        clickable: true,
      },
    });
  }

  if (SliderCarrosselAvaliacoes) {
    const Carrossel = new Swiper(SliderCarrosselAvaliacoes, {
      speed: 400,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: document.querySelector('.receita-avaliacoes__nav .swiper-button-next'),
        prevEl: document.querySelector('.receita-avaliacoes__nav .swiper-button-prev'),
      }
    });
  }
  //NOVIDADES DETALHE - CARD 
  const carousel = document.querySelector('[data-carousel="carrossel-novidades"]');
  if (carousel) {
    let currentIndex = 0;
    const slides = carousel.querySelectorAll('.swiper-slide');
    const itemsPerPage = 2;


    function showSlides() {
      slides.forEach((slide) => {
        slide.style.display = 'none';
      });

      for (let i = currentIndex; i < currentIndex + itemsPerPage; i++) {
        if (slides[i]) {
          slides[i].style.display = 'block';
        }
      }
    }

    function nextSlides() {
      currentIndex += itemsPerPage;

      if (currentIndex >= slides.length) {
        currentIndex = 0;
      }

      showSlides();
    }

    function prevSlides() {
      currentIndex -= itemsPerPage;

      if (currentIndex < 0) {
        currentIndex = Math.floor((slides.length - 1) / itemsPerPage) * itemsPerPage;
      }

      showSlides();
    }

    showSlides();

    const nextButton = document.getElementById('next-btn');
    if (nextButton) {
      nextButton.addEventListener('click', nextSlides);
    }

    const prevButton = document.getElementById('prev-btn');
    if (prevButton) {
      prevButton.addEventListener('click', prevSlides);
    }
  }
  //MENU MOBILE 

  var btMenu = document.getElementById('bt-menu');
  var navMenu = document.querySelector('ul.nav');

  btMenu.addEventListener('click', toggleMenu);

  function toggleMenu() {
    navMenu.classList.toggle('is--active');
    btMenu.classList.toggle('is--active');
    document.body.classList.toggle('has--menu');

  }

  //MENU BT BUSCA 

  var btBusca = document.querySelector('.header-busca__btn');
  var wrapperBusca = document.querySelector('.header-busca');

  btBusca.addEventListener('click', toggleBusca);

  function toggleBusca() {
    wrapperBusca.classList.toggle('is--active');

  }

  //TOGGLE SOBRE NÓS

  var btHumana = document.getElementById('toggle-humana');
  var verHumana = document.getElementById('humana');

  if (btHumana) {

    btHumana.addEventListener('click', toggleHumana);

    function toggleHumana() {
      verHumana.classList.toggle('ativo');
      btHumana.classList.toggle('ativo');
    }

    var btTecnologica = document.getElementById('toggle-tecnologica');
    var verTecnologica = document.getElementById('tecnologica');

    btTecnologica.addEventListener('click', toggleTecnologica);

    function toggleTecnologica() {
      verTecnologica.classList.toggle('ativo');
      btTecnologica.classList.toggle('ativo');
    }

    var btSustentavel = document.getElementById('toggle-sustentavel');
    var verSustentavel = document.getElementById('sustentavel');

    btSustentavel.addEventListener('click', toggleSustentavel);

    function toggleSustentavel() {
      verSustentavel.classList.toggle('ativo');
      btSustentavel.classList.toggle('ativo');
    }
  }


  //botão copiar
  var btsCopiar = document.querySelectorAll('.produto-content__copiar');

  if (btsCopiar) {

    for (var elCopiar of btsCopiar) {
      var btCopiar = elCopiar.querySelector('button');
      btCopiar.addEventListener('click', copiarLink);


      function copiarLink() {
        // Get the text field
        var copyText = elCopiar.querySelector('input');

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);

        btCopiar.classList.add('is--copied');

        window.setTimeout(function () {
          btCopiar.classList.remove('is--copied');
        }, 3000);

      }
    }
  }
  //selecionar todos os ingredientes
  const selecionarTodos = document.getElementById('select-all')
  if (selecionarTodos) {
    const checkboxes = document.querySelectorAll('.receitas-detalhe-content__txt-receita input[type="checkbox"]')
    let lastCheckbox = checkboxes.length
    selecionarTodos.addEventListener('click', () => {
      var isChecked = selecionarTodos.checked
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        if (isChecked) {
          checkboxes[i].checked = true
          checkboxes[i].classList.add('checked')
        } else {
          checkboxes[i].checked = false
          checkboxes[i].classList.remove('checked')
        }
      }
    })
    //aplica classe nos selecionados
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].addEventListener('click', () => {
        if (event.target.classList.contains('checked')) {
          event.target.classList.remove('checked')
        } else {
          event.target.classList.add('checked')
        }
      })
    }
    checkboxes[lastCheckbox - 1].classList.remove('checked')
  }
  //compartilhar ingredientes - precisa ser um pra cada língua, até onde eu sei
  var btShareIngredientesPT = document.getElementById('share_ingredientes-pt');

  if (btShareIngredientesPT) {

    var ingredienteItem = document.querySelectorAll('.form-item');
    var ttlReceita = btShareIngredientesPT.getAttribute('data-ttl');
    var linkReceita = btShareIngredientesPT.getAttribute('data-link');

    for (var elIngredienteItem of ingredienteItem) {
      elIngredienteItem.addEventListener('click', shareIngredientes);

      function shareIngredientes() {

        btShareIngredientesPT.classList.remove('blocked')

        var selecionadosIngredientes = document.querySelectorAll('input.checked');
        var linkBtn = 'https://api.whatsapp.com/send?text=Estes são os ingredientes que estão faltando para sua receita de ' + ttlReceita + '%0A%0A';

        selecionadosIngredientes.forEach(function (itemIngredientes) {
          linkBtn += itemIngredientes.value + '%0A';
        });

        linkBtn += '%0AAcesse: ' + linkReceita;
        btShareIngredientesPT.setAttribute('href', linkBtn);
      }
    }
  }

  var btShareIngredientesEN = document.getElementById('share_ingredientes-en');

  if (btShareIngredientesEN) {

    var ingredienteItem = document.querySelectorAll('.form-item');
    var ttlReceita = btShareIngredientesEN.getAttribute('data-ttl');
    var linkReceita = btShareIngredientesEN.getAttribute('data-link');

    for (var elIngredienteItem of ingredienteItem) {
      elIngredienteItem.addEventListener('click', shareIngredientes);

      function shareIngredientes() {

        btShareIngredientesEN.classList.remove('blocked')

        var selecionadosIngredientes = document.querySelectorAll('input.checked');
        var linkBtn = 'https://api.whatsapp.com/send?text=These are the missing ingredients for the ' + ttlReceita + ' recipe %0A%0A';

        selecionadosIngredientes.forEach(function (itemIngredientes) {
          linkBtn += itemIngredientes.value + '%0A';
        });

        linkBtn += '%0AAcesse: ' + linkReceita;
        btShareIngredientesEN.setAttribute('href', linkBtn);
      }
    }
  }

  var btShareIngredientesES = document.getElementById('share_ingredientes-es');

  if (btShareIngredientesES) {

    var ingredienteItem = document.querySelectorAll('.form-item');
    var ttlReceita = btShareIngredientesES.getAttribute('data-ttl');
    var linkReceita = btShareIngredientesES.getAttribute('data-link');

    for (var elIngredienteItem of ingredienteItem) {
      elIngredienteItem.addEventListener('click', shareIngredientes);

      function shareIngredientes() {

        btShareIngredientesES.classList.remove('blocked')

        var selecionadosIngredientes = document.querySelectorAll('input.checked');
        var linkBtn = 'https://api.whatsapp.com/send?text=Estos son los ingredientes que faltan en tu receta de ' + ttlReceita + '%0A%0A';

        selecionadosIngredientes.forEach(function (itemIngredientes) {
          linkBtn += itemIngredientes.value + '%0A';
        });

        linkBtn += '%0AAcesse: ' + linkReceita;
        btShareIngredientesES.setAttribute('href', linkBtn);
      }
    }
  }

  //indice politica 

  var contentPost = document.querySelector('.politicas-content__conteudo');

  if (contentPost) {
    var contentPostTitles = contentPost.querySelectorAll('h2');
    var contentIndice = document.querySelector('.politicas-content__menu');
    for (var elContentPostTitle of contentPostTitles) {
      var indexTtl = Array.from(contentPostTitles).indexOf(elContentPostTitle);
      var idTtl = 'ttl' + indexTtl;
      elContentPostTitle.setAttribute('id', idTtl);
    }

    var contentPostTitles = contentPost.querySelectorAll('h2');
    for (var elContentPostTitle of contentPostTitles) {
      const elItemIndice = document.createElement('a');
      console.log(elContentPostTitle.textContent);
      const txtPostTitle = document.createTextNode(elContentPostTitle.textContent);
      var indexTtl = Array.from(contentPostTitles).indexOf(elContentPostTitle);
      var idTtl = 'ttl' + indexTtl;
      var linkPostTitle = '#' + idTtl;
      elItemIndice.setAttribute('href', linkPostTitle);
      elItemIndice.appendChild(txtPostTitle);
      contentIndice.appendChild(elItemIndice);
    }
  }


  //form avaliacao receita

  var btAvaliar = document.getElementById('btAvaliar');
  var formAvaliar = document.querySelector('.receita-avaliacoes__form .comment-respond');

  if (btAvaliar) {
    btAvaliar.addEventListener('click', toggleForm);

    function toggleForm() {
      console.log('click');
      formAvaliar.classList.toggle('is--active');
    }
  }

  //modal video receita

  var btsModal = document.querySelectorAll('.bt-modal');

  if (btsModal) {
    var btsFechar = document.querySelectorAll('.bt-fechar');
    var allModal = document.querySelectorAll('.bg_modal');

    for (var elBtModal of btsModal) {
      elBtModal.addEventListener('click', abrirModal);
    }

    for (var elBtFechar of btsFechar) {
      elBtFechar.addEventListener('click', fecharModal);
    }


    function abrirModal() {
      var attrBtModal = event.target.closest('.bt-modal').getAttribute('data-btmodal'); /*pega o atributo data-btmodal do target*/
      document.querySelector('[data-modal="' + attrBtModal + '"]').classList.add('active'); /*encontra qual modal tem o atributo data-modal igual ao data-btmodal do target e adiciona a classe active*/
    }

    function fecharModal() {
      for (var elModal of allModal) {
        elModal.classList.remove('active');
        var iframe = elModal.querySelector('iframe');
        var iframeSrc = iframe.src;
        iframe.src = iframeSrc;
      }
    }

  }

  /*==MENU ACESSIBILIDADE==*/

  var btA11y = document.getElementById('btnA11y');
  var boxA11y = document.querySelector('.a11y__menu');

  if (btA11y) {
    btA11y.addEventListener('click', toggleAcessibilidade);

    function toggleAcessibilidade() {
      boxA11y.classList.toggle('active');
      // btA11y.parentElement.classList.toggle('active');
      console.log(btA11y.parentElement);
      document.querySelector('.a11y').classList.toggle('active');
    }
  }

  // Accessibility

  sessionStorage.getItem('contrast') && +sessionStorage.getItem('contrast') && document.body.classList.add('has--high-contrast');

  if (document.querySelector('[data-contrast]')) {
    document.querySelector('[data-contrast]').addEventListener(
      'click',
      () => {
        // console.log("contraste");
        document.body.classList.toggle('has--high-contrast');

        sessionStorage.setItem('contrast', document.body.classList.contains('has--high-contrast') ? 1 : 0);
      },
      true
    );
  }

  if (sessionStorage.getItem('fontsize')) document.body.style.fontSize = sessionStorage.getItem('fontsize') + 'px';

  if (document.querySelector('[data-fontsize-decrease]')) {
    document.querySelector('[data-fontsize-decrease]').addEventListener(
      'click',
      event => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));
        if (fontsize > 5) {
          document.body.style.fontSize = --fontsize + 'px';

          sessionStorage.setItem('fontsize', fontsize);
        }
      },
      true
    );
  }

  if (document.querySelector('[data-fontsize-increase]')) {
    document.querySelector('[data-fontsize-increase]').addEventListener(
      'click',
      event => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));
        if (fontsize < 15) {
          document.body.style.fontSize = ++fontsize + 'px';

          sessionStorage.setItem('fontsize', fontsize);
        }
      },
      true
    );
  }

  // adiciona classe qndo rola tela
  window.addEventListener('scroll', e => {
    window.scrollY > 50 ? document.body.classList.add('is--scrolling') : document.body.classList.remove('is--scrolling');
  });


  //scroll claims

  var listClaims = document.querySelector('.produto-heading__claims');

  if (listClaims) {
    if (listClaims.scrollWidth > listClaims.clientWidth) {
      listClaims.classList.add('has--scroll');
    }

    window.addEventListener('resize', function (event) {
      if (listClaims.scrollWidth > listClaims.clientWidth) {
        listClaims.classList.add('has--scroll');
      }
    });
  }

  // video novidade-detalhe
  const videoContainer = document.getElementById('video-container');
  if (videoContainer) {
    videoContainer.addEventListener('click', function () {
      const container = this;

      // Substitui a img por um iframe do YouTube
      container.innerHTML = `
    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/JGqXG0L88-E?si=YRQr-hhcs_rQiTxI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    `;

      container.classList.add('playing');
    });
  } 
  
// Filtro de pesquisa da página onde comprar
const filtros = document.querySelectorAll('.onde-busca__filtro'); 
const campoBusca = document.querySelector('.onde-busca__field input'); 
const resultadosContainer = document.querySelector('.onde-busca__resultados'); 
const textoResultados = document.querySelector('.onde-busca p'); 
const botao = document.querySelector('.onde-busca__field button'); 

function limparResultados() {
  textoResultados.style.display = 'none';  
  resultadosContainer.style.display = 'none';
}

function mostrarResultados() {
  resultadosContainer.style.display = 'flex';
  textoResultados.style.display = 'block'; 
}

function mostrarFiltros() {
  filtros.forEach(filtro => {
    filtro.style.display = 'flex'; 
  });
}

function esconderFiltros() {
  filtros.forEach(filtro => {
    filtro.style.display = 'none'; 
  });
}

botao.addEventListener('click', function() {
  const termoBusca = campoBusca.value.trim();

  if (termoBusca !== '') {
    mostrarResultados();
    mostrarFiltros();
  } else {
    limparResultados();
    esconderFiltros();
  }
});

filtros.forEach(filtro => {
  filtro.addEventListener('click', function(event) {
    if (event.target === filtro || event.target === filtro.querySelector('span')) {
      filtro.style.display = 'none'; 
      limparResultados();
    }
  });
});

// Acionar a pesquisa com enter
document.querySelector(".onde-busca__field").addEventListener("keyup", function(event) {
  if (event.key === "Enter") {
      this.querySelector("button").click();
  }
});

  /*==COOKIES==*/

  (function () {
    'use strict';

    var cookieAlert = document.getElementById('cookiealert');
    var acceptCookies = document.getElementById('acceptcookies');

    if (!cookieAlert) {
      return;
    } else {
      // console.log('cookies');
    }

    cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

    // Show the alert if we cant find the "acceptCookies" cookie
    if (!getCookie('acceptCookies')) {
      cookieAlert.classList.add('show');
    }

    // When clicking on the agree button, create a 1 year
    // cookie to remember user's choice and close the banner
    acceptCookies.addEventListener('click', function () {
      setCookie('acceptCookies', true, 365);
      cookieAlert.classList.remove('show');
    });

    // Cookie functions from w3schools
    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    function getCookie(cname) {
      var name = cname + '=';
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    }
  })();

  var wrapperHomeChildren = document.querySelectorAll('.home-content .wrapper > *');

  if (wrapperHomeChildren) {
    for (var elWrapperHomeChildren of wrapperHomeChildren) {
      elWrapperHomeChildren.setAttribute('data-aos', 'fade-up');
    }
  }

  var wrapperSobreChildren = document.querySelectorAll('.sobre-content__grid > *');

  if (wrapperSobreChildren) {
    for (var elWrapperSobreChildren of wrapperSobreChildren) {
      elWrapperSobreChildren.setAttribute('data-aos', 'fade-up');
    }
  }

  AOS.init();
}